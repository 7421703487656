import * as React from 'react';
import {SanityKeyed} from '@mindfulness/cms';

import {
  Maybe,
  ReferencedNavigation,
  ReferencedNavigationMegaMenu,
  ReferencedSideNavigation,
} from '../../../types/types';
import {Link, Context, Icon} from '../../global';
import {H3, Text} from '../../typography';
import {Grid, Stack} from '../../layout';
import {TextOnImageCard} from '../../ui';
import {useCreateModal} from '../../../hooks';

import {
  ArticleLink,
  BackButton,
  BackButtonLabel,
  DrawerFooter,
  DrawerInner,
  DrawerNavItem,
  DrawerNavLabel,
  DrawerNavList,
  DrawerSubMenu,
  DrawerWrapper,
  SeeAllLabel,
  SeeAllLink,
  SubMenuHeader,
  SubMenuMain,
} from './Header.styles';
import {loggedInItems} from '../../../utils/navigation';
import {DrawerProfile} from './DrawerProfile';

export const Drawer: React.FC<Props> = ({
  navigation,
  sideNavigation,
  announcement,
}) => {
  const modalProps = useCreateModal({name: 'drawer'});
  const {session} = React.useContext(Context);

  const href = React.useCallback(
      (url: Maybe<string>, target: ReferencedNavigationMegaMenu['target']) => {
        if (url) {
          return url;
        }

        if (target?.slug?.current) {
          if (target.noHub) {
            return `${target.slug.current}/browse`;
          }
          return target.slug.current;
        }
        return undefined;
      },
      [],
  );

  return (
    <DrawerWrapper announcement={announcement} open={modalProps.open}>
      <DrawerInner>
        <DrawerNavList>
          {session ? (
            <>
              {loggedInItems.map(({label, link}) => {
                return (
                  <Link href={link} passHref key={link}>
                    <DrawerNavItem>
                      <DrawerNavLabel>{label}</DrawerNavLabel>
                      <Icon name="arrow" size={24} />
                    </DrawerNavItem>
                  </Link>
                );
              })}
            </>
          ) : (
            navigation?.linklist?.map((props) => (
              <NavItem
                {...props}
                href={href(props.link?.url, props.target)}
                key={props._key}
              />
            ))
          )}
        </DrawerNavList>
        <DrawerFooter>
          <Stack direction="vertical" space={34}>
            {sideNavigation?.linklist?.map(({_key, ...props}) => (
              <React.Fragment key={_key}>
                {props.url || props.slug?.current ? (
                  <Link href={props.url || props.slug?.current} passHref>
                    <Text as="a" fontSize="lg" colour="grey9">
                      {props.title}
                    </Text>
                  </Link>
                ) : null}
              </React.Fragment>
            ))}
          </Stack>
        </DrawerFooter>
      </DrawerInner>
      <DrawerProfile />
    </DrawerWrapper>
  );
};


const NavItem: React.FC<
  SanityKeyed<ReferencedNavigationMegaMenu> & { href?: string }
> = ({href: parentHref, gallery, articles, link}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const href = React.useCallback(
      (
          url: Maybe<string>,
          target: Maybe<{
        slug: {
          current?: string;
        };
        title?: string;
        noHub?: boolean;
      }>,
      ) => {
        if (url) {
          return url;
        }
        if (target?.slug?.current) {
          if (target.noHub) {
            return `${target.slug.current}/browse`;
          }
          return target.slug.current;
        }
        return '/';
      },
      [],
  );
  if (!link) return null;
  return (
    <>
      <Link href={parentHref} passHref>
        <DrawerNavItem>
          <DrawerNavLabel>{link.title}</DrawerNavLabel>
          <Icon name="arrow" size={24} />
        </DrawerNavItem>
      </Link>
      <DrawerSubMenu open={open}>
        <SubMenuHeader>
          <BackButton onClick={() => setOpen(false)}>
            <Icon name="left-arrow" size={24} />
            <BackButtonLabel>{link.title}</BackButtonLabel>
          </BackButton>
          {parentHref ? (
            <Link href={parentHref} passHref>
              <SeeAllLink>
                <SeeAllLabel>{`See All ${link.title}`}</SeeAllLabel>
                <Icon name="arrow" size={16} />
              </SeeAllLink>
            </Link>
          ) : null}
        </SubMenuHeader>
        <SubMenuMain>
          <H3 weight="bold" spacing={12}>
            Browse
          </H3>
          <Grid cols={2} rowGap={10} columnGap={10} spacing={30}>
            {gallery?.map(({title, image, url, target, _key}) =>
              url || target ? (
                <Link href={href(url, target)} key={_key}>
                  <a>
                    <TextOnImageCard
                      title={title || target?.title}
                      image={
                        image ||
                        target?.image ||
                        target?.hubImage ||
                        target?.topicImage
                      }
                      fontSize="md"
                    />
                  </a>
                </Link>
              ) : null,
            )}
          </Grid>
          <H3 weight="bold" spacing={12}>
            Top articles
          </H3>
          {articles?.map(({article}) =>
            article?.slug?.current ? (
              <Link
                href={article.slug.current}
                passHref
                key={article.slug.current}
              >
                <ArticleLink>
                  <Text colour="grey9" fontSize="md">
                    {article.title}
                  </Text>
                  <Icon name="arrow" size={16} />
                </ArticleLink>
              </Link>
            ) : null,
          )}
        </SubMenuMain>
      </DrawerSubMenu>
    </>
  );
};

type Props = {
  navigation: ReferencedNavigation;
  sideNavigation: ReferencedSideNavigation;
  announcement?: boolean;
};
