import React from 'react';
import {toggle} from '@mindfulness/utils/boolean';
import {Context} from '../Provider';
import style from './DrawerProfile.module.css';

import {ProfileDetails, ProfileMenu} from 'shared/components/ui';
import {Box} from 'shared/components/layout';
import {Icon} from '../Icon';
import {Title3} from 'shared/components/typography';
import {ButtonLink} from '../ButtonLink';
import {isOnClient} from 'utils/next';

export const DrawerProfile: React.FC = () => {
  const ref = React.useRef<HTMLDivElement>(null);
  const {session} = React.useContext(Context);
  const [open, setOpen] = React.useState(false);
  const maxHeight = React.useMemo(() => {
    if (ref.current) {
      return ref.current.scrollHeight;
    }
    return 0;
  }, [ref.current]);
  return (
    <>
      {session ? (
        <div className={style.profileWrapper}>
          <button type="button" className={style.profileMenuButton} onClick={() => setOpen(toggle)}>
            <ProfileDetails />
            <div className={[
              style.iconWrapper,
              ...(open ? [style.open] : []),
            ].join(' ')}>
              <Icon name="chevron" size={24} />
            </div>
          </button>
          <div className={style.profileMenuContainer} ref={ref} style={{
            height: open ? maxHeight : 0,
          }}>
            <Box paddingY={20}>
              <ProfileMenu />
            </Box>
          </div>
        </div>
      ) : (
        <div className={style.signInBox}>
          <Title3 weight="bold" spacing={16}>
            Already have an account?
          </Title3>
          <ButtonLink
            size="md"
            href={
              isOnClient() ?
                `/login?onto=${window.location.pathname}&view=login` :
                '/login'
            }
            id={'MobileSignIn'}
            wide
          >
            Sign in
          </ButtonLink>
        </div>
      )}
    </>
  );
};
